import { createSlice } from "@reduxjs/toolkit"

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        userProfile: null 
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload
        },
        logout: (state) => {
            state.user = null
        },
        setLoginDetails: (state, action) => {
            state.user = action.payload
        },
        setUserProfile: (state, action) => {
            state.userProfile = action.payload
        }
    }
})

export const {
    login,
    logout,
    setLoginDetails,
    setUserProfile,
    userProfile
} = authSlice.actions

export const selectUser = (state) => state.authUser
export const selectUserProfile = (state) => state.authUser.userProfile

export default authSlice.reducer
