import { createSlice } from "@reduxjs/toolkit"

export const submissionSlice = createSlice({
    name: "submission",
    initialState: {
        submissionList: [],
        submissionDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setSubmissionList: (state, action) => {
            state.submissionList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.submissionDetail = action.payload
        }
    }
})

export const {
    setSubmissionList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = submissionSlice.actions

export const selectSubmissionList = (state) => state.submission.submissionList
export const selectFilterTerm = (state) => state.submission.filterTerm
export const selectTotalEntry = (state) => state.submission.totalEntry
export const selectSubmissionDetail = (state) => state.submission.submissionDetail

export default submissionSlice.reducer
