// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './slices/auth.Slice'
import users from './slices/usersSlice'
import industry from './slices/industrySlice'
import dimension from './slices/dimensionSlice'
import indicator from './slices/indicatorSlice'
import question from './slices/questionSlice'
import dimensionESG from './slices/dimensionESGSlice'
import indicatorSDG from './slices/indicatorSDGSlice'
import submission from './slices/submissionSlice'
import section from './slices/sectionSlice'
import recentSubmission from './slices/recentSubmissions'
import recentProgressSubmission from './slices/recentProgressSubmissions'
import associatedCompanies from './slices/associatedCompaniesSlice'
import showDocument from './slices/showDocumentSlice'
import theme from './slices/themeSlice'
import readiness from './slices/readinessSlice'
import actionPlan from './slices/actionPlanSlice'
import questionProgress from './slices/questionProgressSlice'

const rootReducer = {
    navbar,
    layout,
    auth,
    users,
    industry,
    dimension,
    indicator,
    question,
    dimensionESG,
    indicatorSDG,
    submission,
    section,
    recentSubmission,
    recentProgressSubmission,
    associatedCompanies,
    showDocument,
    theme,
    readiness,
    actionPlan,
    questionProgress
}

export default rootReducer
