import { createSlice } from "@reduxjs/toolkit"

export const recentProgressSubmissionSlice = createSlice({
    name: "recentProgressSubmission",
    initialState: {
        recentProgressSubmissionList: [],
        recentProgressSubmissionDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setRecentProgressSubmissionList: (state, action) => {
            state.recentProgressSubmissionList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.recentProgressSubmissionDetail = action.payload
        }
    }
})

export const {
    setRecentProgressSubmissionList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = recentProgressSubmissionSlice.actions

export const selectRecentProgressSubmissionList = (state) => state.recentProgressSubmission.recentProgressSubmissionList
export const selectFilterTerm = (state) => state.recentProgressSubmission.filterTerm
export const selectTotalEntry = (state) => state.recentProgressSubmission.totalEntry
export const selectRecentSubmissionDetail = (state) => state.recentProgressSubmission.recentProgressSubmissionDetail

export default recentProgressSubmissionSlice.reducer