import { createSlice } from "@reduxjs/toolkit"

export const associatedCompaniesSlice = createSlice({
    name: "associatedCompanies",
    initialState: {
        associatedCompaniesList: [],
        associatedCompaniesDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setAssociatedCompaniesList: (state, action) => {
            state.associatedCompaniesList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.associatedCompaniesDetail = action.payload
        }
    }
})

export const {
    setAssociatedCompaniesList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = associatedCompaniesSlice.actions

export const selectAssociatedCompaniesList = (state) => state.associatedCompanies.associatedCompaniesList
export const selectFilterTerm = (state) => state.associatedCompanies.filterTerm
export const selectTotalEntry = (state) => state.associatedCompanies.totalEntry
export const selectAssociatedCompaniesDetail = (state) => state.associatedCompanies.associatedCompaniesDetail

export default associatedCompaniesSlice.reducer
