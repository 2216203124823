import { createSlice } from "@reduxjs/toolkit"

export const indicatorSDGSlice = createSlice({
    name: "indicatorSDG",
    initialState: {
        indicatorSDGList: [],
        indicatorSDGDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setIndicatorSDGList: (state, action) => {
            state.indicatorSDGList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.indicatorSDGDetail = action.payload
        }
    }
})

export const {
    setIndicatorSDGList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = indicatorSDGSlice.actions

export const selectindicatorSDGList = (state) => state.indicatorSDG.indicatorSDGList
export const selectFilterTerm = (state) => state.indicatorSDG.filterTerm
export const selectTotalEntry = (state) => state.indicatorSDG.totalEntry
export const selectindicatorSDGDetail = (state) => state.indicatorSDG.indicatorSDGDetail

export default indicatorSDGSlice.reducer
