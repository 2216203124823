import { createSlice } from "@reduxjs/toolkit"

export const questionProgressSlice = createSlice({
    name: "questionProgress",
    initialState: {
        questionProgressList: [],
        questionProgressDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setQuestionProgressList: (state, action) => {
            state.questionProgressList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.questionProgressDetail = action.payload
        }
    }
})

export const {
    setQuestionProgressList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = questionProgressSlice.actions

export const selectquestionProgressList = (state) => state.questionProgress.questionProgressList
export const selectFilterTerm = (state) => state.questionProgress.filterTerm
export const selectTotalEntry = (state) => state.questionProgress.totalEntry
export const selectquestionProgressDetail = (state) => state.questionProgress.questionProgressDetail

export default questionProgressSlice.reducer
