import { createSlice } from "@reduxjs/toolkit"

export const actionPlanSlice = createSlice({
    name: "actionPlan",
    initialState: {
        actionPlanList: [],
        actionPlanDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setActionPlanList: (state, action) => {
            state.actionPlanList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.actionPlanDetail = action.payload
        }
    }
})

export const {
    setActionPlanList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = actionPlanSlice.actions

export const selectactionPlanList = (state) => state.actionPlan.actionPlanList
export const selectFilterTerm = (state) => state.actionPlan.filterTerm
export const selectTotalEntry = (state) => state.actionPlan.totalEntry
export const selectactionPlanDetail = (state) => state.actionPlan.actionPlanDetail

export default actionPlanSlice.reducer
