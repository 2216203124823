import { createSlice } from "@reduxjs/toolkit"

export const recentSubmissionSlice = createSlice({
    name: "recentSubmission",
    initialState: {
        recentSubmissionList: [],
        recentSubmissionDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setRecentSubmissionList: (state, action) => {
            state.recentSubmissionList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.recentSubmissionDetail = action.payload
        }
    }
})

export const {
    setRecentSubmissionList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = recentSubmissionSlice.actions

export const selectRecentSubmissionList = (state) => state.recentSubmission.recentSubmissionList
export const selectFilterTerm = (state) => state.recentSubmission.filterTerm
export const selectTotalEntry = (state) => state.recentSubmission.totalEntry
export const selectRecentSubmissionDetail = (state) => state.recentSubmission.recentSubmissionDetail

export default recentSubmissionSlice.reducer