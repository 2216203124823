import { createSlice } from "@reduxjs/toolkit"

export const dimensionESGSlice = createSlice({
    name: "dimensionESG",
    initialState: {
        dimensionESGList: [],
        dimensionESGDetail: {},
        isFreshData: false,
        totalEntry: 0,
        filterTerm: ''
    }, 
    reducers: {
        setDimensionESGList: (state, action) => {
            state.dimensionESGList = action.payload
        },
        setFilterTerm: (state, action) => {
            state.filterTerm = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.dimensionESGDetail = action.payload
        }
    }
})

export const {
    setDimensionESGList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = dimensionESGSlice.actions

export const selectdimensionESGList = (state) => state.dimensionESG.dimensionESGList
export const selectFilterTerm = (state) => state.dimensionESG.filterTerm
export const selectTotalEntry = (state) => state.dimensionESG.totalEntry
export const selectdimensionESGDetail = (state) => state.dimensionESG.dimensionESGDetail

export default dimensionESGSlice.reducer
